import * as logClient from "@classdojo/log-client";
import { Platform } from "@classdojo/log-client/lib/client/client";
import * as Components from "@classdojo/web";
import { logClick } from "@web-monorepo/shared/events/clickLogging";
import { EventRecorder } from "app/pods/devTools";
import deployGroup from "app/utils/deployGroup";
import env from "app/utils/env";
import { translate } from "app/utils/translate";
import { metrics } from "app/utils/metrics";

declare global {
  interface Window {
    build: string;
    appVersion: string;
    logClient: typeof logClient;
    msCrypto: Crypto;
  }
}

Components.initialize({
  // @ts-expect-error Types of parameters 'object' and 'str' are incompatible.
  translate,
  logEvent: (...args) => logClient.logEvent(...args),
});

export let platform: Platform = "web";
if (window?.location) {
  // for home islands web view, mobile apps will pass the platform in the query params
  // so we can initialize logClient
  const urlParams = new URLSearchParams(window?.location.search);
  const platformQueryParam = urlParams.get("platform") || "";
  if (platformQueryParam.toLowerCase() === "android") {
    platform = "android";
  } else if (platformQueryParam.toLowerCase() === "ios") {
    platform = "ios";
  }
}

// for home islands web view, mobile apps will pass the platform in the query params
// so we can initialize logClient with a transformEventName function that will prefix the events
// with the host platform
const transformEventName =
  platform !== "web"
    ? (eventName: string) => {
        return `${platform}.${eventName}`;
      }
    : undefined;

if ((env.isProd && typeof window !== "undefined") || env.isCypress) {
  window.appVersion = Config.appVersion;
  window.build = Config.buildNumber;

  logClient.init({
    env: env.isProd ? "prod" : "testing",
    deployGroup,
    site: "student",
    customEndpoint: "/logs",
    buildNumber: Config.buildNumber,
    appVersion: Config.appVersion,
    platform,
    transformEventName,
  });
} else if (env.isDev) {
  logClient.init({ env: "dev", site: "student", transformEventName });
} else if (env.isTesting) {
  logClient.init({ env: "testing", disabledForTest: true, site: "student", transformEventName });
}

window.addEventListener("unload", () => {
  logClient.flush();
  metrics.close();
});

EventRecorder.start();

document.body.addEventListener("click", (ev) => logClick(ev, "student"), { capture: true });
